import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverviewAccordionBlock } from '@/components/AccordionBlock';

type MobileDoubleListDialogContentProps = {
  titleForAvailableItem: string;
  availableItemView: NonNullable<ReactNode>;
  titleForSelectedItem: string;
  selectedItemView: NonNullable<ReactNode>;
};
export const MobileDoubleListDialogContent = ({
  titleForAvailableItem,
  availableItemView,
  titleForSelectedItem,
  selectedItemView,
}: MobileDoubleListDialogContentProps) => {
  const { t } = useTranslation();
  const [isSelectedExpanded, setIsSelectedExpanded] = useState(false);
  const toggleViewSelectedItems = () => {
    setIsSelectedExpanded((prevState) => !prevState);
  };

  return (
    <ContentWrapper>
      <OverviewAccordionBlock
        title={t(titleForSelectedItem)}
        expanded={isSelectedExpanded}
        onChange={toggleViewSelectedItems}
      >
        {selectedItemView}
      </OverviewAccordionBlock>
      <OverviewAccordionBlock
        title={t(titleForAvailableItem)}
        expanded={!isSelectedExpanded}
        onChange={toggleViewSelectedItems}
      >
        {availableItemView}
      </OverviewAccordionBlock>
    </ContentWrapper>
  );
};

const ContentWrapper = styled(Box)(() => ({
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));
